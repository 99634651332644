// noinspection SpellCheckingInspection
import {Injectable} from '@angular/core'
import {formatNumber, formatPercent} from '@angular/common'
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

import {ColorBar} from "plotly.js"
import {
    Observable,
    combineLatest,
    debounceTime,
    firstValueFrom,
    forkJoin,
    map,
    of,
    startWith,
    switchMap,
    tap
} from 'rxjs'

import {MAPBOX_MARKERS, MapboxMarker} from 'src/app/mapbox/mapbox-helper'
import {
    assertNever,
    average,
    dateToString,
    enableFields,
    inArray,
    isDefined,
    isNotNull,
    saveSpreadsheet,
    valueOrFirst
} from 'src/app/shared/vide-helper'
import {
    ArrayElement,
    objectWithPosition,
    pick,
    PlotlyScattermapboxData,
    VideFigure,
    VideObjectWithPosition,
} from 'src/app/vide-types'
import {FORM_DEBOUNCE_TIME, getMapPlotTextFont} from 'src/constants'
import {AbstractMapboxService} from "../../mapbox/abstract-mapbox.service"
import {
    measurementChecked,
    measurementCheckedNumeric,
    measurementNonAffected,
    measurementNonAffectedNumeric
} from "../measurement-categories"
import {getNewTransform, MeasurementTransformKind, timeWeightAverage} from '../plot-functions'
import {Measurement, MeasurementResponse, MeasureType, ProjectWithLimit, VideObject} from "../../api/api-types"
import {regression} from "../../shared/LinearRegression"
import {Schema} from "write-excel-file"


export const TREND_NR_DAYS = 30

const FORMATTING_LOCALE = "en_US"
export const MAP_PLOT_KIND_ATTRIBUTES = [
    'Bottom level',
    'Reference level',
    'Length', // = Reference level - Bottom level
    'Ground level',
    'Bottom depth', // = Ground level - Bottom level
    'Permanently affected after'
] as const
export const MAP_PLOT_KIND_MEASUREMENT_VALUES = [
    'First',
    'First (value)',
    'Last',
    'Last (value)',
    'Trend',
    'Min',
    'Max',
    'Average',
    // 'Average all measurements',
    'Time weighted average',
    'Average yearly minimum',
    'Average yearly maximum',
] as const
export const MAP_PLOT_KIND_STATISTICS = [
    'Number of measurements',
    'Number of measurements with value',
    'Share of measurements having value',
    'First date',
    'First date (value)',
    'Last date',
    'Last date (value)',
] as const

export const MAP_PLOT_KIND_VALUES = [
    ...MAP_PLOT_KIND_ATTRIBUTES,
    ...MAP_PLOT_KIND_MEASUREMENT_VALUES,
    ...MAP_PLOT_KIND_STATISTICS,
] as const

type MapPlotKind = typeof MAP_PLOT_KIND_VALUES[number]

const FORM_FIELDS_MEASUREMENT: ReadonlyArray<keyof FormType['options']> = [
    'transformKind',
] as const
const FORM_FIELDS_STATISTICS: ReadonlyArray<keyof FormType['options']> = [
    'measureType',
    'first',
    'last',
    'zeroLevelDateTime',
    // 'zeroTime',
] as const
const FORM_FIELD_COLORSCALE: ReadonlyArray<keyof FormType['colorScale']> = ['cmin', 'cmax'] as const

interface MapPlotValue {
    value: number | string | null
    error?: string
}

interface ColorbarProperties {
    cmin?: number
    cmid?: number
    cmax?: number
    colorbar: Partial<ColorBar>
}

type FormType = Readonly<ReturnType<MapPlotDataService['form']['getRawValue']>>

function getColorbar42(measureType: Readonly<MeasureType>, valueKind: Readonly<MeasurementTransformKind>): ColorbarProperties {
    const title = measureType.measure_unit.name
    switch (valueKind) {
        case 'Deviation from average':
        case 'Deviation from average excluding affected values':
            return {colorbar: {title}, cmid: 0,}
        case 'Fill rate':
            return {colorbar: {title: '%'}, cmin: 0, cmax: 100}
        case 'Resulting value':
        case 'Below surface':
            return {colorbar: {title}}
        case 'Measured value':
            return {colorbar: {title: title + '?'}}
        case "Deviation from zero level date":
        case "Negative deviation from zero level date":
            return {colorbar: {title}}
        default:
            assertNever(valueKind)
    }
}

function getFormatter(arg: {
    // measureType: Readonly<MeasureType2>,
    displayKind: Readonly<typeof MAP_PLOT_KIND_VALUES[number]>,
    transformKind: MeasurementTransformKind
}): (v: string | number) => {
    short: string,
    long: string,
    colorValue: number
} {
    function getShortPercent(v: number) {
        return formatPercent(v, FORMATTING_LOCALE, '1.0-0')
    }

    function getLongPercent(v: number) {
        return formatPercent(v, FORMATTING_LOCALE, '.0-8')
    }

    switch (arg.displayKind) {
        // Whole numbers
        case "Number of measurements":
        case "Number of measurements with value":
            return v => {
                if (typeof v !== 'number') throw Error("Wrong type of value")
                return {
                    short: v.toString(),
                    long: v.toString(),
                    colorValue: v
                }
            }
        // Percent values
        case "Share of measurements having value":
            // if (arg.transformKind==='Below surface') console.error("Strange transform")
            return v => {
                if (typeof v !== 'number') throw Error("Wrong type of value")
                return {
                    short: getShortPercent(v),
                    long: getLongPercent(v),
                    colorValue: v * 100
                }
            }
        // Date
        case "First date":
        case "First date (value)":
        case "Last date":
        case "Last date (value)":
        case "Permanently affected after":
            // cut the date string to YYYY-MM-DD (10 chars)
            return v => {
                if (typeof v !== 'string') throw Error("Wrong type of value")
                return {
                    short: v.substring(0, 10),
                    long: v,
                    colorValue: (new Date(v)).getTime()
                }
            }
    }
    let shortNumberStringFn: (v: number) => string
    let longNumberStringFn: (v: number) => string
    let colorNumberFn: (v: number) => number
    if (arg.transformKind === 'Fill rate') {
        shortNumberStringFn = (v: number) => getShortPercent(v)
        longNumberStringFn = (v: number) => getLongPercent(v)
        colorNumberFn = (v: number) => v * 100
    } else {
        shortNumberStringFn = (v: number) => formatNumber(v, FORMATTING_LOCALE, '.0-2')
        longNumberStringFn = (v: number) => v.toString()
        colorNumberFn = (v: number) => v
    }
    switch (arg.displayKind) {
        // Other, normal digits
        case "Bottom level":
        case "Reference level":
        case "Length":
        case "Ground level":
        case "Bottom depth":
        case "First":
        case "First (value)":
        case "Last":
        case "Last (value)":
        case "Trend":
        case "Min":
        case "Max":
        case "Average":
        // case "Average all measurements":
        case "Time weighted average":
        case "Average yearly minimum":
        case "Average yearly maximum":
            return v => {
                if (typeof v !== 'number') throw Error("Wrong type of value")
                return {
                    short: shortNumberStringFn(v),
                    long: longNumberStringFn(v),
                    colorValue: colorNumberFn(v)
                }
            }
        default:
            assertNever(arg.displayKind)
    }
}

@Injectable({
    providedIn: 'root',
})
export class MapPlotDataService extends AbstractMapboxService {
    readonly form = this.formBuilder.nonNullable.group({
        colorScale: this.formBuilder.nonNullable.group({
            cmax: [0],
            cmin: [0],
            fixColorScale: [false],
        }),
        map: this.mapForm,
        options: this.formBuilder.nonNullable.group({
            displayKind: ['Last' as MapPlotKind],
            first: [''],
            fontSize: [12],
            includeAffected: [true],
            last: [''],
            marker: [MAPBOX_MARKERS[1] as MapboxMarker],
            measureType: [null as MeasureType | null],
            objectNameInLabel: [false],
            onlyValue: [false],
            showLabels: [true],
            transformKind: ['Resulting value' as MeasurementTransformKind],
            useFontSize: [false],
            zeroLevelDateTime: [''],
        }),
    })
    readonly selectedObjects$ = this.dataService.selectedObjects$
    readonly plotlyStyle$ = this.dataService.plotlyStyle$
    readonly objectsWithoutCoordinates$ = this.dataService.selectedObjects$.pipe(
        map(os => os.filter(o => !objectWithPosition(o))))
    readonly objectsWithCoordinates$ = this.dataService.selectedObjects$.pipe(
        map(os => os.filter(objectWithPosition)))
    readonly measureTypes$ = this.objectsWithCoordinates$.pipe(
        map(os => {
            const map = new Map<number, MeasureType>()
            os.forEach(o => o.statistics.forEach(s => {
                map.set(s.measure_type.id, s.measure_type)
            }))
            return Array.from(map.values())
        }),
        tap(ts => {
            const firstType = ts.at(0)
            const control = this.form.controls.options.controls.measureType
            if (control.pristine) {
                setTimeout(() => {
                    control.patchValue(firstType ?? null)
                })
            }
        }),
    )
    popupRef$ = this.dataService.popupRef$
    protected readonly useColorbar: boolean = true
    private objectsWithValue = Array<VideObject>()
    readonly xFigure$ = combineLatest([
        this.dataService.projectNotNull$,
        this.objectsWithCoordinates$,
        this.form.valueChanges.pipe(
            startWith(null),
            debounceTime(FORM_DEBOUNCE_TIME),
            map(_ => this.form.getRawValue())),
        this.dataService.plotlyToImage$,
        this.forceRelayout$,
    ]).pipe(
        switchMap(([project, objects, options, toImage, _]) => {
            const config = this.getMapConfig(toImage.toImageOptions)
            const worker = this.getWorkerV2(project, options.options)
            // Get measurements even for objects without this measure type. Then we will have the object in the map with 'No value'
            const x = objects.map(o => worker.valueFn(o))
            const observable = x.length > 0 ? forkJoin(x) : of([])
            const layout = this.getLayout(project, options.options.onlyValue ? this.objectsWithValue : objects,)
            return combineLatest([observable, layout]).pipe(
                map(([values, layout]) => {
                    // console.log("New layout",layout.mapbox?.layers)
                    const figureData = this.getFigureData(values, options, worker.colorbar, worker.formatter)
                    const figure: VideFigure = {data: figureData.data, config, layout}
                    return {
                        figure,
                        ...pick(figureData, 'exportData', 'colorspan'),
                    }
                }),
            )
        }),
    )

    readonly figure$ = this.xFigure$.pipe(
        map(x => x.figure),
    )

    constructor() {
        super()
        this.setupControls()
    }

    get plotlyOptions() {
        return this.dataService.plotlyOptions
    }

    get options() {
        return this.form.getRawValue()
    }

    set options(value: ReturnType<typeof this.form.getRawValue>) {
        this.form.patchValue(value)
    }

    async saveData() {
        // const x = await firstValueFrom(this.xxx$)
        const x = await firstValueFrom(this.xFigure$)
        if (!x) {
            throw Error("No data to save")
        }
        const exportData = x.exportData
        const lengths = new Set([exportData.lat.length, exportData.lon.length, exportData.name.length, exportData.value.length])
        if (lengths.size !== 1) {
            throw Error("All properties must have equal length")
        }
        const zipped = Array.from({length: exportData.lat.length}).map((_, i) => ({
            lat: exportData.lat.at(i)!,
            lon: exportData.lon.at(i)!,
            name: exportData.name.at(i)!,
            value: exportData.value.at(i)!,
        }))
        const schema: Schema<typeof zipped[number]> = [
            {column: 'Name', type: String, value: x => x.name},
            {column: 'Lat', type: Number, value: x => x.lat},
            {column: 'Lon', type: Number, value: x => x.lon},
        ]
        const valueRow: typeof schema[number] = typeof exportData.value.at(0) === 'number' ? {
            column: 'Value',
            type: Number,
            value: x => x.value
        } : {column: 'Value', type: Date, value: x => new Date(x.value), width: 19,}
        schema.splice(1, 0, valueRow,)
        return saveSpreadsheet(zipped, schema)
    }

    transformationDisabled(t: MeasurementTransformKind) {
        const options = this.form.controls.options.controls.measureType.value
        return this.transformDisabledInternal(t, options)
    }

    selectObjectsWithValue() {
        const oids = this.objectsWithValue.map(o => o.id)
        this.dataService.selectionModel.setSelection(...oids)
    }

    private transformDisabledInternal(t: MeasurementTransformKind, mt: MeasureType | null) {
        return mt?.constant_name !== 'measure_type_level' && t === 'Below surface'
    }

    private setupControls() {
        const controls = this.form.controls

        controls.options.controls.displayKind.valueChanges.pipe(
            takeUntilDestroyed(),
            startWith(controls.options.controls.displayKind.value)
        ).subscribe(kind => {
            const statisticsKind = inArray(MAP_PLOT_KIND_STATISTICS, kind)
            const measurementKind = inArray(MAP_PLOT_KIND_MEASUREMENT_VALUES, kind)
            enableFields(this.form.controls.options, FORM_FIELDS_MEASUREMENT, measurementKind)
            enableFields(this.form.controls.options, FORM_FIELDS_STATISTICS, measurementKind || statisticsKind)
            // The other fields are always enabled
        })
        controls.colorScale.controls.fixColorScale.valueChanges.pipe(
            takeUntilDestroyed(),
            startWith(controls.colorScale.controls.fixColorScale.value)
        ).subscribe(fix => {
            enableFields(this.form, FORM_FIELD_COLORSCALE, fix)
        })
        controls.options.controls.measureType.valueChanges.pipe(
            takeUntilDestroyed(),
        ).subscribe(mt => {
            if (this.transformDisabledInternal(this.form.controls.options.controls.transformKind.value, mt)) {
                this.form.patchValue({options: {transformKind: 'Resulting value'}})
            }
        })
    }

    private getFigureData(
        input: ReadonlyArray<{ object: VideObjectWithPosition } & MapPlotValue>,
        options: FormType,
        marker: ColorbarProperties,
        formatter: (value: string | number) => { short: string, long: string, colorValue: number }
    ) {
        this.objectsWithValue.splice(0)
        const lat = Array<number>()
        const lon = Array<number>()
        const hovertext = Array<string>()
        const text = Array<string>()
        const name = Array<string>()
        const values = Array<string | number>()
        const color = Array<number>()
        const lat_n = Array<number>()
        const lon_n = Array<number>()
        const text_n = Array<string>()

        input.forEach(({object, value, error}) => {
            if (value !== null) {
                this.objectsWithValue.push(object)
                const formatted = formatter(value)

                lat.push(object.position.lat)
                lon.push(object.position.lon)
                hovertext.push(object.name + ': ' + formatted.long)
                text.push((options.options.objectNameInLabel ? (object.name + ' : ') : ('')) + formatted.short)
                name.push(object.name)
                values.push(value)
                color.push(formatted.colorValue)
            } else if (!options.options.onlyValue) {
                lat_n.push(object.position.lat)
                lon_n.push(object.position.lon)
                text_n.push(object.name + ' : ' + (error ?? 'No value'))
            }
        })
        const mmm = {...marker}

        if (options.colorScale.fixColorScale) {
            // console.warn(`Fixed colorscale`, options.cmin, options.cmax)
            mmm.cmin = options.colorScale.cmin
            mmm.cmax = options.colorScale.cmax
        }
        const normalSize = valueOrFirst(options.options.marker.marker.size, 8)
        const textfont = getMapPlotTextFont(this.mapForm.controls.background.value)
        if (options.options.useFontSize) {
            textfont.size = options.options.fontSize
        }

        // noinspection SpellCheckingInspection
        const data: Array<PlotlyScattermapboxData> = [
            {
                // Backdrop black circle, to produce a black edge on the marker.
                // Make it 1 pixel larger than the normal marker.
                lat,
                lon,
                marker: {
                    size: normalSize + 1,
                    color: 'black',
                },
                type: 'scattermapbox',
            },
            {
                // main marker
                hoverinfo: 'text',
                hovertext,
                lat,
                lon,
                marker: {
                    ...mmm,
                    color,
                    colorscale: 'RdBu',
                    reversescale: true,
                    // showscale: true,
                    ...options.options.marker.marker,
                },
                mode: options.options.showLabels ? 'text+markers' : 'markers',
                text,
                textfont,
                textposition: 'middle right',
                type: 'scattermapbox',
            },
            {
                // error marker
                hoverinfo: 'text',
                'lat': lat_n,
                'lon': lon_n,
                marker: {
                    color: 'black',
                    opacity: 0.5,
                    size: 6,
                    /**
                     * Scattermapbox uses symbols from https://labs.mapbox.com/maki-icons/.
                     * Color only applies to symbol 'circle',
                     allowoverlap: true,
                     symbol: ICONS,
                     symbol: 'circle-stroked',
                     size: 4, // matches circle with default size 6.
                     */
                },
                'text': text_n,
                // hovertext: ICONS,
                type: 'scattermapbox',
            },
        ]
        const exportData = {lat, lon, value: values, name}

        const getDataAggregate = (agg: (...values: number[]) => number) => {
            const value = agg(...color)
            return (isFinite(value) ? value : undefined)
        }

        const colorspan = {
            cmin: mmm.cmin ?? getDataAggregate(Math.min),
            cmax: mmm.cmax ?? getDataAggregate(Math.max)
        }

        return {
            colorspan,
            data,
            exportData,
        }
    }

    private getWorkerV2(proj: ProjectWithLimit, options: Readonly<FormType['options']>): {
        readonly valueFn: (o: Readonly<VideObjectWithPosition>) => Observable<Readonly<{
            object: VideObjectWithPosition
        } & MapPlotValue>>,
        readonly colorbar: ColorbarProperties,
        readonly formatter: (x: number | string) => { short: string, long: string, colorValue: number }
    } {
        const filters = options.includeAffected ? {
            all: (_object: VideObject) => measurementChecked,
            numeric: (_object: VideObject) => measurementCheckedNumeric
        } : {
            all: measurementNonAffected,
            numeric: measurementNonAffectedNumeric
        }


        const undoneResponse = {
            colorbar: {colorbar: {}},
            formatter: () => {
                return {short: 'error', long: 'error', colorValue: NaN}
            },
            valueFn: (object: VideObjectWithPosition) => of({object, value: NaN, error: "Not correct"})
        }
        const measureType = options.measureType
        if (!isDefined(measureType)) {
            return undoneResponse
        }
        const displayKind = options.displayKind
        const transformKind = options.transformKind

        const colorbarForMeasureType = getColorbar42(measureType, transformKind)
        const colorbarForLength = {colorbar: {title: 'm'}} as const
        const colorbarForTime = {colorbar: {showticklabels: false}} as const
        const colorbarForNumber = {colorbar: {title: 'N',}} as const
        const formatterMeasureType = getFormatter({displayKind, transformKind: 'Resulting value'})

        // Here, we do not care about transformKind
        switch (displayKind) {
            // Object attributes are straightforward. But transform is not used, maybe disable it?
            case "Bottom level":
                return {
                    colorbar: colorbarForMeasureType,
                    formatter: formatterMeasureType,
                    valueFn: (o) => of({object: o, value: o.bottom_level})
                }
            case "Reference level":
                return {
                    colorbar: colorbarForMeasureType,
                    formatter: formatterMeasureType,
                    valueFn: (o) => of({object: o, value: o.reference_level})
                }
            case "Length":
                return {
                    colorbar: colorbarForLength,
                    formatter: formatterMeasureType,
                    valueFn: (o) => {
                        const value = (o.reference_level !== null && o.bottom_level !== null)
                            ? o.reference_level - o.bottom_level
                            : null
                        return of({object: o, value})
                    }
                }
            case "Ground level":
                return {
                    colorbar: colorbarForMeasureType,
                    formatter: formatterMeasureType,
                    valueFn: (o) => of({object: o, value: o.ground_level})
                }
            case "Bottom depth":
                return {
                    colorbar: colorbarForLength,
                    formatter: formatterMeasureType,
                    valueFn: (o) => {
                        const value = (o.ground_level !== null && o.bottom_level !== null)
                            ? o.ground_level - o.bottom_level
                            : null
                        return of({object: o, value})
                    }
                }
            case "Permanently affected after":
                return {
                    colorbar: colorbarForTime,
                    formatter: formatterMeasureType,
                    valueFn: (o) => of({object: o, value: o.not_reference_from})
                }

            // intentional drop-off, here we can do some general preparations for the comming cases
        }

        /** Get the measurements between the option's dates first-last*/
        function getMeasurementSlice(dataSet: readonly Measurement[]) {
            return dataSet.filter(dateFilter(options.first, options.last))
        }

        switch (displayKind) {
            // Measurement stats require the correct data slice
            case "Number of measurements":
                return {
                    colorbar: colorbarForNumber,
                    formatter: formatterMeasureType,
                    valueFn: object => {
                        return this.dataService.getMeasurements(proj, object, measureType).pipe(
                            map(r => {
                                const slice = getMeasurementSlice(r.measurements)
                                const value = slice.filter(filters.all(object)).length
                                return {object, value}
                            })
                        )
                    }
                }
            case "Number of measurements with value":
                return {
                    colorbar: colorbarForNumber,
                    formatter: formatterMeasureType,
                    valueFn: object => {
                        return this.dataService.getMeasurements(proj, object, measureType).pipe(
                            map(r => {
                                const slice = getMeasurementSlice(r.measurements)
                                const value = slice.filter(filters.numeric(object)).length
                                return {object, value}
                            })
                        )
                    }
                }
            case "Share of measurements having value":
                return {
                    colorbar: {colorbar: {title: '%'}},
                    formatter: formatterMeasureType,
                    valueFn: object => {
                        return this.dataService.getMeasurements(proj, object, measureType).pipe(
                            map(r => {
                                const slice = getMeasurementSlice(r.measurements)
                                const n = slice.filter(filters.all(object)).length
                                const nValue = slice.filter(filters.numeric(object)).length
                                const value = n === 0 ? null : nValue / n
                                // const value = nValue / n
                                return {object, value}
                            })
                        )
                    }
                }
        }

        const getSliceDate = (arg: {
            filter: (object: VideObjectWithPosition) => (x: Measurement) => boolean,
            position: number,
        }) => (object: VideObjectWithPosition) => {
            return this.dataService.getMeasurements(proj, object, measureType).pipe(
                map(r => {
                    const value = getMeasurementSlice(r.measurements)
                        .filter(arg.filter(object))
                        .at(arg.position)?.measuretime ?? null
                    return {object, value}
                })
            )
        }

        switch (displayKind) {
            case "First date":
                return {
                    colorbar: colorbarForTime,
                    formatter: formatterMeasureType,
                    valueFn: getSliceDate({filter: filters.all, position: 0})
                }
            case "First date (value)":
                return {
                    colorbar: colorbarForTime,
                    formatter: formatterMeasureType,
                    valueFn: getSliceDate({filter: filters.numeric, position: 0})
                }
            case "Last date":
                return {
                    colorbar: colorbarForTime,
                    formatter: formatterMeasureType,
                    valueFn: getSliceDate({filter: filters.all, position: -1})
                }
            case "Last date (value)":
                return {
                    colorbar: colorbarForTime,
                    formatter: formatterMeasureType,
                    valueFn: getSliceDate({filter: filters.numeric, position: -1})
                }

            // intentional drop-off, here we can do some general preparations for the comming cases
        }

        const formatterValue = getFormatter({displayKind, transformKind})
        // const zeloLevelDateTime = [options.zero, options.zeroTime].join(' ')
        const transform = getNewTransform({
            transformKind: transformKind,
            measureType: measureType,
            zeroLevelDateTime: options.zeroLevelDateTime
        })


        const getSliceSingleValue = (
            filter: (object: VideObjectWithPosition) => (x: Measurement) => boolean,
            position: number,
        ) => {
            return (object: VideObjectWithPosition) => {
                return this.dataService.getMeasurements(proj, object, measureType).pipe(
                    map(r => {
                        const slice = getMeasurementSlice(r.measurements).filter(filter(object))
                        const x = slice.at(position)
                        const value = x ? transform(object, slice)(x) : null
                        return {object, value}
                    })
                )
            }
        }


        // Here we *do* care about transformKind
        switch (displayKind) {
            // Measurement values require some more work
            case "First":
                return {
                    colorbar: colorbarForMeasureType,
                    formatter: formatterValue,
                    valueFn: getSliceSingleValue(filters.all, 0)
                }
            case "First (value)":
                return {
                    colorbar: colorbarForMeasureType,
                    formatter: formatterValue,
                    valueFn: getSliceSingleValue(filters.numeric, 0)
                }
            case "Last":
                return {
                    colorbar: colorbarForMeasureType,
                    formatter: formatterValue,
                    valueFn: getSliceSingleValue(filters.all, -1)
                }
            case "Last (value)":
                return {
                    colorbar: colorbarForMeasureType,
                    formatter: formatterValue,
                    valueFn: getSliceSingleValue(filters.numeric, -1)
                }
            // intentional drop-off, here we can do some general preparations for the comming cases
        }

        function getTimeAndValues(r: MeasurementResponse, o: VideObjectWithPosition) {
            const slice = getMeasurementSlice(r.measurements)
                .filter(filters.numeric(o))
            const tr = transform(o, slice)
            return slice.map(m => {
                const value = tr(m)
                return value ? {time: m.measuretime, value} : null
            }).filter(isNotNull)
        }

        const aggregateValueFn = (
            aggregateFn: (...values: number[]) => number,
        ) => {
            return (object: VideObjectWithPosition) => {
                return this.dataService.getMeasurements(proj, object, measureType).pipe(
                    map(r => {
                        // const slice = getMeasurementSlice(r.measurements).filter(filters.numeric(object))
                        // const tr = transform(object, slice)
                        // const values = slice.map(m => tr(m)).filter(isNotNull)
                        const values = getTimeAndValues(r, object).map(x => x.value)
                        const value = values.length > 0 ? aggregateFn(...values) : null
                        return {object, value}
                    })
                )
            }
        }

        const getTrend = (o: VideObjectWithPosition) =>
            this.dataService.getMeasurements(proj, o, measureType).pipe(
                map(r => {
                    const values = getTimeAndValues(r, o)
                    const x = values.map(m => Date.parse(m.time))
                    const y = values.map(m => m.value)
                    const reg = regression(x, y)
                    const value = reg.b * msPerPeriod  // unit per ms
                    console.log(reg)
                    return {object: o, value}
                }),
            )

        const msPerPeriod = 1000 * 60 * 60 * 24 * TREND_NR_DAYS

        switch (displayKind) {
            case "Trend":
                return {
                    colorbar: {colorbar: {title: `${measureType.measure_unit.name} / ${TREND_NR_DAYS} days`}},
                    formatter: formatterValue,
                    valueFn: getTrend,
                }
            case "Min":
                return {
                    colorbar: colorbarForMeasureType,
                    formatter: formatterValue,
                    valueFn: (o) => aggregateValueFn(Math.min)(o),
                }
            case "Max":
                return {
                    colorbar: colorbarForMeasureType,
                    formatter: formatterValue,
                    valueFn: (o) => aggregateValueFn(Math.max)(o),
                }
            case "Average":
                return {
                    colorbar: colorbarForMeasureType,
                    formatter: formatterValue,
                    valueFn: (o) => aggregateValueFn(average)(o),
                }
            case "Time weighted average":
                return {
                    colorbar: colorbarForMeasureType,
                    formatter: formatterValue,
                    valueFn: object => {
                        return this.dataService.getMeasurements(proj, object, measureType).pipe(
                            map(r => {
                                const values = getTimeAndValues(r, object)
                                const value = timeWeightAverage(values)
                                return {object, value}
                            })
                        )
                    }
                }
        }

        const yearlyFn = (aggregate: (...values: number[]) => number) => {
            return (object: VideObjectWithPosition) => {
                return this.dataService.getMeasurements(proj, object, measureType).pipe(
                    map(r => {
                        const values = getTimeAndValues(r, object)
                        const value = getYearlyAverageNew(values, aggregate)
                        return {object, value}
                    })
                )
            }
        }

        switch (displayKind) {
            case "Average yearly minimum":
                return {
                    colorbar: colorbarForMeasureType,
                    formatter: formatterValue,
                    valueFn: yearlyFn(Math.min)
                }
            case "Average yearly maximum":
                return {
                    colorbar: colorbarForMeasureType,
                    formatter: formatterValue,
                    valueFn: yearlyFn(Math.max)
                }
            default:
                assertNever(displayKind)
        }

    }
}

function getYearlyAverageNew(
    measurements: ReadonlyArray<{ time: string, value: number }>,
    // slice: ReadonlyArray<MeasurementWithValue>,
    aggregate: (...values: number[]) => number,
) {
    let value = null
    if (measurements.length > 0) {
        let acc = Array<number>()
        const chunks = Array<typeof acc>()
        let currYear = '0000'
        for (const it of measurements) {
            const y = it.time.substring(0, 4) // the year
            if (y !== currYear) {
                currYear = y
                chunks.push(acc)
                acc = Array<ArrayElement<typeof acc>>()
            }
            acc.push(it.value)
        }
        chunks.push(acc)

        const values = chunks.filter(a => a.length > 0)
        const years = values.length
        const aggregates = values.map(a => aggregate(...a))
        const sum = aggregates.reduce((acc, curr) => acc + curr)
        value = sum / years
    }
    return value
}

function dateFilter(first: string, last: string): (m: Measurement) => boolean {
    const tomorrow = new Date(last)
    tomorrow.setDate(tomorrow.getDate() + 1)
    const tomorrowString = dateToString(tomorrow)
    // console.log(first, tomorrowString)
    return (m) => {
        return (first === '' || first <= m.measuretime) &&
            (last === '' || m.measuretime < tomorrowString)
    }
}
